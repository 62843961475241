@import '../../variables';

.error-404 {
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background-color: #adb5bd;

    & > div {
        flex: 0 1 auto;

        &:nth-child(1) {
            color: #f1f3f5;
            font-size: 240px;
            line-height: 240px;
            font-weight: 700;
            margin-right: 30px;
        }

        &:nth-child(2) {
            margin-top: 12px;
        }

        & span {
            text-transform: uppercase;
            display: block;

            &:nth-child(1) {
                color: #e9ecef;
                font-size: 34px;
                line-height: 34px;
            }

            &:nth-child(2) {
                color: #dee2e6;
                font-size: 94px;
                line-height: 94px;
                font-weight: 700;
            }

            &:nth-child(3) {
                color: #ced4da;
                font-size: 48px;
                line-height: 48px;
            }
        }
    }
}
