@import '../../../variables';

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    transition: 150ms all ease-in-out;
    background-color: transparent;
    color: #fff;

    &--scrolled {
        background-color: #fff;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
        color: $focco-blue;
    }

    &__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 24px 0 16px;
        transition: 150ms all ease-in-out;

        &--scrolled {
            padding: 16px 0 14px;
        }
    }

    &__admin-bar {
        background-color: #212529;
        color: #666;
        font-size: 13px;
        padding: 4px 0;
        text-align: right;

        &-separator {
            padding: 0 16px;
        }

        & a {
            color: $focco-orange;
        }
    }

    &__logo {
        flex: 0 1 auto;
        margin: 1%;
    }

    &__tagline {
        flex: 0 1 auto;
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        border-left: 1px solid $focco-orange;
        margin-left: 24px;
        padding-left: 24px;
        transition: 150ms all ease-in-out;

        &--scrolled {
            color: $focco-blue;
        }
    }

    &__menu {
        flex: 0 1 auto;
        margin-left: auto;
        display: inline-flex;
        align-items: center;

        &-auth {
            position: relative;
            flex: 0 1 auto;
            display: inline-flex;
            flex-flow: column nowrap;
            text-align: right;
            margin-right: 40px;

            &:after {
                content: '';
                position: absolute;
                right: -20px;
                top: 50%;
                transform: translateY(-50%) rotate(135deg);
                display: inline-flex;
                width: 12px;
                height: 12px;
                border-top: 1px solid #dee2e6;
                border-left: 1px solid #dee2e6;
            }

            & > div:nth-child(1) {
                font-size: 12px;
                line-height: 12px;
                color: #ffffff;
            }

            & > div:nth-child(2) {
                line-height: 18px;
                color: $focco-orange;
            }

            &--scrolled {
                &:after {
                    border-top: 1px solid $focco-blue;
                    border-left: 1px solid $focco-blue;
                }

                & > div:nth-child(1) {
                    color: $focco-blue;
                }
            }
        }

        & > ul {
            flex: 0 1 auto;
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: center;
            margin: 0;
            padding: 0;
        }

        & > li {
            margin: 0;
            padding: 0;
        }

        &-item {
            position: relative;
            display: inline-flex;
            margin-right: 24px;

            &:first-of-type {
                margin-left: 16px;
            }

            &:last-of-type {
                margin-right: 16px;
            }

            &-dropdown {
                position: absolute;
                top: 100%;
                right: 0;
                width: 200px;
                margin-top: 12px;
                text-align: right;
                color: #ffffff;
                font-size: 12px;
                transition: 150ms all ease-in-out;
                opacity: 1;
                visibility: visible;

                &--scrolled {
                    top: 50%;
                    opacity: 0;
                    visibility: hidden;
                }

                & span {
                    border-bottom: 1px solid $focco-orange;
                }
            }
        }
    }
}
