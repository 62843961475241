$focco-silver: #adb5bd;
$focco-gold: #faad14;
$focco-orange: #f4911c;
$focco-red: #f5222d;
$focco-blue: #2d506c;
$focco-light-blue: #339af0;
$focco-light-green: #40c057;

$spacing-sm: 12px;
$spacing-md: 18px;
$spacing-lg: 24px;
