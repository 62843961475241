@import '../variables';

.auth {
    &__loading {
        position: fixed;
        z-index: 9999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        animation: auth-fade-in 150ms ease-in-out;

        &-enter {
            opacity: 0;
        }

        &-enter-active {
            opacity: 1;
            transition: opacity 150ms ease-in;
        }

        &-exit {
            opacity: 1;
        }

        &-exit-active {
            opacity: 0;
            transition: opacity 150ms ease-in;
        }

        & div:nth-child(2) {
            margin-top: 10px;
            color: rgba(255, 255, 255, 0.9);
            font-size: 22px;
        }
    }
}

.loader {
    margin: 0 auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.8em solid rgba(255, 255, 255, 0.2);
    border-right: 0.8em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.8em solid rgba(255, 255, 255, 0.2);
    border-left: 0.8em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: 6em;
    height: 6em;

    &:after {
        border-radius: 50%;
        width: 6em;
        height: 6em;
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes auth-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
