@import '../../variables';

$admin-header-height: 60px;
$admin-footer-height: 60px;

.admin {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    &__content {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        margin-top: $admin-header-height;
    }

    &__header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: $admin-header-height;
        background-color: #212529;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        z-index: 999;

        &-title {
            flex: 0 1 auto;

            & h1 {
                color: #ffffff;
                font-size: 22px;
                line-height: 22px;
                font-weight: 400;
                margin-bottom: 0;

                & span {
                    font-size: 13px;
                    line-height: 13px;
                    color: $focco-orange;
                    font-weight: 300;
                    display: block;
                }
            }
        }

        &-menu {
            flex: 0 1 auto;

            & > ul {
                margin: 0;
                padding: 0;
            }

            &-item {
                display: inline-flex;
                margin-left: 16px;
                color: #666;

                & a {
                    color: rgba(255, 255, 255, 1);
                    transition: 150ms all ease-in-out;

                    &:hover {
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
    }

    &__page {
        &-content {
            padding: 20px;

            &--with-footer {
                margin-bottom: $admin-footer-height;
            }
        }

        &-footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            border-top: 1px solid #ced4da;
            z-index: 999;
            background-color: #fff;
            padding: 12px 20px;
            height: $admin-footer-height;
        }

        &-footer-cli {
            position: static;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            border-top: 1px solid #ced4da;
            z-index: 999;
            background-color: #fff;
            padding: 12px 20px;
            height: $admin-footer-height;
        }
    }

    &-table {
        &__row {
            cursor: pointer;
        }
    }
}
