@import '../../variables';

.button {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    border: 1px solid transparent;
    text-decoration: none;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
    transition: 150ms all ease-in-out;

    &--xs {
        height: 20px;
        padding: 0 8px;
        font-size: 10px;
        border-radius: 8px;
    }

    &--sm {
        height: 22px;
        padding: 0 8px;
        font-size: 12px;
        border-radius: 12px;
    }

    &--md {
        height: 36px;
        padding: 0 10px;
        font-size: 15px;
        border-radius: 18px;
    }

    &--lg {
        height: 44px;
        padding: 0 16px;
        font-size: 22px;
        border-radius: 26px;
    }

    &--xl {
        height: 52px;
        padding: 0 24px;
        font-size: 24px;
        border-radius: 26px;
    }

    &--orange {
        color: #fff;
        border-color: $focco-orange;
        background-color: $focco-orange;

        &:hover {
            border-color: darken($focco-orange, 10%);
            background-color: darken($focco-orange, 10%);
        }
    }

    &--outlined#{&}--orange {
        color: $focco-orange;
        border-color: $focco-orange;
        background-color: transparent;

        &:hover {
            color: #ffffff;
            background-color: $focco-orange;
        }
    }

    &--flat#{&}--orange {
        color: $focco-orange;
        border-color: transparent;
        background-color: transparent;

        &:hover {
            color: darken($focco-orange, 10%);
            background-color: #f1f3f5;
        }
    }

    &--white {
        color: #444444;
        border-color: #ffffff;
        background-color: #ffffff;

        &:hover {
            border-color: darken(#ffffff, 10%);
            background-color: darken(#ffffff, 10%);
        }
    }

    &--outlined#{&}--white {
        color: #ffffff;
        border-color: #ffffff;
        background-color: transparent;

        &:hover {
            color: #444444;
            background-color: #fff;
        }
    }

    &--flat#{&}--white {
        color: #ffffff;
        border-color: transparent;
        background-color: transparent;

        &:hover {
            color: darken(#fff, 10%);
        }
    }

    &--blue {
        color: #ffffff;
        border-color: $focco-blue;
        background-color: $focco-blue;

        &:hover {
            border-color: darken($focco-blue, 10%);
            background-color: darken($focco-blue, 10%);
        }
    }

    &--outlined#{&}--blue {
        color: $focco-blue;
        border-color: $focco-blue;
        background-color: transparent;

        &:hover {
            color: #444444;
            background-color: #fff;
        }
    }

    &--flat#{&}--blue {
        color: $focco-blue;
        border-color: transparent;
        background-color: transparent;

        &:hover {
            color: darken($focco-blue, 10%);
            background-color: #f1f3f5;
        }
    }

    &__content {
        flex: 1;
    }
}
