@import '../../../variables';

.footer {
    flex: 0 1 auto;

    &__bottom {
        padding: 32px 0;
        background-color: #303030;

        & > .common-container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        &-copy {
            flex: 0 1 auto;
            color: #ffffff;

            & span {
                color: $focco-orange;
            }
        }

        &-links {
            flex: 0 1 auto;
            margin-left: auto;

            & li {
                display: inline-block;
                margin-left: 20px;

                & a {
                    color: #ffffff;
                    font-weight: 600;

                    &:hover {
                        color: darken(#ffffff, 12%);
                    }
                }
            }
        }
    }
}
