@import '../../variables';

.authenticated-user {
    align-items: center;
    display: flex;
    flex: 0 1 auto;
    flex-flow: row nowrap;
    margin-left: auto;

    > span {
        align-items: center;
        display: flex;
    }

    &__tooltip.ant-tooltip {
        max-width: 600px;
    }

    &__content {
        display: flex;
        flex-flow: column nowrap;
        font-size: 12px;
        line-height: 1.1em;
        margin-left: 16px;
        text-align: right;

        &--highlight {
            color: $focco-orange;
            line-height: 1em;
            font-size: 16px;
            font-weight: 400;
        }

        &--icon {
            border: 1px solid $focco-orange;
            border-radius: 50%;
            color: $focco-orange;
            font-size: 20px;
            padding: 10px;
        }
    }

    &__separator {
        color: #666;
        margin-left: 16px;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        flex-flow: row nowrap;

        > span:last-of-type {
            margin-left: 12px;
            min-width: 60px;
            text-align: right;
        }
    }
}
