@import 'variables';

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Roboto Slab', serif;
    color: #495057;
    font-size: 16px;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

button {
    font-family: 'Roboto Slab', serif;
    font-size: 16px;
    font-weight: 700;
}

a {
    color: $focco-blue;
    text-decoration: none;
    transition: 200ms all ease-in-out;

    &:active {
        color: $focco-blue;
    }

    &:hover {
        color: lighten($focco-blue, 12%);
    }
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

strong {
    font-weight: 700;
}

#root {
    height: 100%;
}

.common-page {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    transition: all 1000ms ease-in-out;

    &-enter {
        opacity: 0;
    }

    &-enter-active {
        opacity: 1;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        opacity: 0;
    }
}

.common-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

// @media min-width é igual ou maior
// Abaixo de 576 se tornará 100% e atendera a mobiles

// [667, 736] mobiles landscape
@media (min-width: 576px) {
    .common-container {
        width: 520;
    }
}

// [768, 812, 834] Tables e mobiles landscape
@media (min-width: 768px) {
    .common-container {
        width: 730px;
    }
}

// [1024]
@media (min-width: 992px) {
    .common-container {
        width: 970px;
    }
}

// [1280, 1366]
@media (min-width: 1200px) {
    .common-container {
        width: 1170px;
    }
}

// [1440]
@media (min-width: 1400px) {
    .common-container {
        width: 1270px;
    }
}

// [1680, 1920]
@media (min-width: 1640px) {
    .common-container {
        width: 1470px;
    }
}

.common-button-text {
    color: $focco-orange;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;

    &--white {
        color: #fff;
    }

    &--blue {
        color: $focco-blue;
    }

    &--orange {
        color: $focco-orange;
    }
}

.common-pill-separator {
    display: inline-block;
    width: 20px;
    height: 4px;
    border-radius: 2px;
    background-color: $focco-blue;

    &--sm {
        width: 20px;
    }

    &--md {
        width: 40px;
    }

    &--lg {
        width: 60px;
    }

    &--white {
        background-color: #ffffff;
    }

    &--orange {
        background-color: $focco-orange;
    }

    &--blue {
        background-color: $focco-blue;
    }
}

.common-margin {
    /* prettier-ignore */
    &--sm { margin: $spacing-sm; }
    /* prettier-ignore */
    &--md { margin: $spacing-md; }
    /* prettier-ignore */
    &--lg { margin: $spacing-lg; }

    &-h {
        /* prettier-ignore */
        &--sm { margin-right: $spacing-sm; margin-left: $spacing-sm; }
        /* prettier-ignore */
        &--md { margin-right: $spacing-md; margin-left: $spacing-md; }
        /* prettier-ignore */
        &--lg { margin-right: $spacing-lg; margin-left: $spacing-lg; }
    }

    &-v {
        /* prettier-ignore */
        &--sm { margin-top: $spacing-sm; margin-bottom: $spacing-sm; }
        /* prettier-ignore */
        &--md { margin-top: $spacing-md; margin-bottom: $spacing-md; }
        /* prettier-ignore */
        &--lg { margin-top: $spacing-lg; margin-bottom: $spacing-lg; }
    }

    &-top {
        /* prettier-ignore */
        &--sm { margin-top: $spacing-sm; }
        /* prettier-ignore */
        &--md { margin-top: $spacing-md; }
        /* prettier-ignore */
        &--lg { margin-top: $spacing-lg; }
    }

    &-right {
        /* prettier-ignore */
        &--sm { margin-right: $spacing-sm; }
        /* prettier-ignore */
        &--md { margin-right: $spacing-md; }
        /* prettier-ignore */
        &--lg { margin-right: $spacing-lg; }
    }

    &-bottom {
        /* prettier-ignore */
        &--sm { margin-bottom: $spacing-sm; }
        /* prettier-ignore */
        &--md { margin-bottom: $spacing-md; }
        /* prettier-ignore */
        &--lg { margin-bottom: $spacing-lg; }
    }

    &-left {
        /* prettier-ignore */
        &--sm { margin-left: $spacing-sm; }
        /* prettier-ignore */
        &--md { margin-left: $spacing-md; }
        /* prettier-ignore */
        &--lg { margin-left: $spacing-lg; }
    }
}

.froala {
    & .fr-toolbar,
    & .second-toolbar {
        border-color: #ced4da;
    }

    & .fr-box.fr-basic .fr-wrapper {
        border-left-color: #ced4da;
        border-right-color: #ced4da;
    }

    & .fr-element.fr-disabled {
        background-color: #f5f5f5;
    }

    & .second-toolbar > a#logo {
        visibility: hidden;
    }

    &--error {
        & .fr-toolbar,
        & .second-toolbar {
            border-color: #f5222d;
        }

        & .fr-box.fr-basic .fr-wrapper {
            border-left-color: #f5222d;
            border-right-color: #f5222d;
        }
    }
}

.ant-input,
.ant-input-disabled,
.ant-input[disabled],
.ant-input-number,
.ant-input-number-disabled,
.ant-input-number[disabled],
.ant-select,
.ant-select .ant-select-selection--multiple .ant-select-selection__choice,
.ant-select-disabled,
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
    color: #414141;
    font-weight: 400;
}

.ant-input-number,
.ant-calendar-picker {
    width: 100%;
}

.ant-page-header {
    padding-left: 0;
    padding-right: 0;
}

.ant-btn-primary,
.ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary:hover,
.ant-btn-primary:visited {
    background-color: $focco-orange;
    border-color: $focco-orange;
}

.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-nav .ant-tabs-tab:active,
.ant-tabs-nav .ant-tabs-tab:focus,
.ant-tabs-nav .ant-tabs-tab:hover,
.ant-tabs-nav .ant-tabs-tab:visited {
    color: inherit;
}

.ant-tabs-ink-bar {
    background-color: $focco-orange;
}

.gold {
    color: $focco-gold;
}

.orange {
    color: $focco-orange;
}

.red {
    color: $focco-red;
}

.debito {
    color: $focco-red;
}

.credito {
    color: $focco-light-green;
}

.ql-toolbar
{
    line-height: 0px;

    & .ql-picker-options {
        line-height: 20px;
    }
}

.ql-editor {
    min-height: 100px;
    word-break: break-all;
}


.ql-disabled {
    background-color: #f5f5f5;

}